function sendForm(form = {}) {
  if (!form.message || !form.email) {
    return Promise.reject('Missing mandatory data');
  }

  const data = {
    attachments: [
      {
        pretext: form.message,
        fields: [
          {
            title: 'Name',
            value: form.name,
            short: false
          },
          {
            title: 'Company',
            value: form.company,
            short: false
          },
          {
            title: 'Email',
            value: form.email,
            short: false
          },
          {
            title: 'Phone',
            value: form.phone,
            short: false
          },
        ],
      }
    ]
  };

  return fetch('https://hooks.slack.com/services/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', {
    method: 'POST',
    headers: {
      // 'Content-Type': 'application/json; charset=utf-8', // bypass CORS issue for now
    },
    body: JSON.stringify(data),
  })
}

export default sendForm;