import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Section from './Section';
import blueGrey from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
  container: {
    backgroundColor: blueGrey[900],
  },
  text: {
    color: theme.palette.background.default,
    textAlign: 'center',
  },
});

function Footer(props) {
  const { classes } = props;
  const year = new Date().getFullYear();

  return (
    <Section extraClasses={classes.container}>
      <p className={classes.text}>
      © Kawatta {year}
      </p>
    </Section>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);