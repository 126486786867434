import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Section from './Section';
import sendForm from './sendForm';
import lightBlue from '@material-ui/core/colors/lightBlue';

const styles = theme => ({
  container: {
    backgroundColor: lightBlue[50],
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    // color: theme.palette.background.default,
    // borderColor: theme.palette.background.default,
    // borderWidth: 2,
    margin: theme.spacing.unit
  },
  buttons: {
    textAlign: 'end',
  },
  chip: {
    margin: theme.spacing.unit,
  },
  title: {
    width: '100%',
  },
  form: {
    width: '100%',
  },
});

class Contact extends React.Component {

  constructor() {
    super();
    this.sendMessage = this.sendMessage.bind(this);
    this.handleMessageFeedbackClose = this.handleMessageFeedbackClose.bind(this);
    this.showMessageFeedback = this.showMessageFeedback.bind(this);
  }

  state = {
    form: {
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
    },
    messageFeedbackOpen: false,
    messageFeedbackError: false,
  };

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [prop]: event.target.value,
      },
    });
  };

  handleMessageFeedbackClose() {
    this.setState({
      ...this.state,
      messageFeedbackOpen: false,
      messageFeedbackError: false,
    });
  }
  
  showMessageFeedback(isError) {
    this.setState({
      ...this.state,
      messageFeedbackOpen: true,
      messageFeedbackError: isError,
    });
  }

  sendMessage() {
    sendForm(this.state.form)
      .then(() => {
        this.showMessageFeedback(false);
        this.setState({
          ...this.state,
          form: {
            name: '',
            company: '',
            email: '',
            phone: '',
            message: '',
          },
        });
      })
      .catch(() => {
        this.showMessageFeedback(true);
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Section extraClasses={classes.container} id="contact">
        <Typography component="h1" variant="h4" color="textPrimary" gutterBottom className={classes.title}>
          Get in touch
        </Typography>
        <form noValidate className={classes.form}>
          <Grid container spacing={24} justify="flex-end">
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                id="name" 
                fullWidth 
                value={this.state.form.name}
                onChange={this.handleChange('name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company"
                id="company"
                fullWidth
                value={this.state.form.company}
                onChange={this.handleChange('company')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                id="email"
                fullWidth
                type="email"
                value={this.state.form.email}
                onChange={this.handleChange('email')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone number"
                id="phone"
                fullWidth
                type="tel"
                value={this.state.form.phone}
                onChange={this.handleChange('phone')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                id="message"
                fullWidth
                multiline
                value={this.state.form.message}
                onChange={this.handleChange('message')}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={this.sendMessage}>
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.messageFeedbackOpen}
          autoHideDuration={6000}
          onClose={this.handleMessageFeedbackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={this.state.messageFeedbackError ? <span id="message-id">An error happened and your message wasn't sent, please retry again</span> : <span id="message-id">Thank you very much, we will get back to you as soon as possible</span>}
        />
      </Section>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);