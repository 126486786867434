import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Hero from './Hero';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <Hero />
          <Services />
          <Contact />
        </main>
        <footer>
          <Divider />
          <Footer />
        </footer>
      </React.Fragment>
    );
  }
}

export default App;
