import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Typewriter from 'typewriter-effect';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Logo from './Logo.js';
import background from './background_1x.jpg';

const styles = theme => ({
  heroUnit: {
    flexGrow: 1,
    height: '100vh',
    backgroundImage: `linear-gradient(#000000a6, #000000a6), url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  heroContent: {
    margin: '0 auto',
    // paddingTop: '20vh',
    position: 'relative',
    top: '30%',
    [theme.breakpoints.up('md')]: {
      top: '20%',
    },
    ['@media (max-height:450px)']: { // eslint-disable-line no-useless-computed-key
      top: '5%',
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit * 8,
    },
    ['@media (max-height:450px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: theme.spacing.unit,
    }
  },
  title: {
    color: theme.palette.background.default,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.palette.background.default,
    maxWidth: '90%',
    margin: '0 auto',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: theme.palette.background.default,
  },
  logo: {
    height: '2em',
    marginRight: theme.spacing.unit,
  },
});

function Title({ className, width }) {
  let variant = 'h4';

  if (isWidthUp('sm', width)) {
    variant = 'h2';
  }
  
  return (
    <Typography variant={variant} align="center" paragraph className={className}>
      Got an <span role="img" aria-label="awesome">🚀</span> idea ?
    </Typography>
  );
}

const HeroTitle = withWidth()(Title);

const subtitleTypewriter = (typewriter) => {
  typewriter
    .typeString('a reality').pauseFor(3000).deleteAll()
    .typeString('a website').pauseFor(1000).deleteAll()
    .typeString('a service').pauseFor(1000).deleteAll()
    .typeString('an App').pauseFor(1000).deleteAll()
    .typeString('a success!')
    .start();
}

function Subtitle({ className, width }) {
  let variant = 'h5';

  if (isWidthUp('sm', width)) {
    variant = 'h4';
  }

  return (
    <Typography variant={variant} align="center" className={className}>
      Your vision together with our technical expertise, we can make it <Typewriter onInit={subtitleTypewriter} /> 
    </Typography>
  );
}

const HeroSubtitle = withWidth()(Subtitle);

function Hero(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={classes.heroUnit}>
        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
          <Toolbar>
            <Logo className={classes.logo} />
            <Typography variant="h5" color="primary" className={classes.grow}>
              Kawatta
            </Typography>
            <Button color="primary" className={classes.menuButton} href="#services">Services</Button>
            <Button color="primary" className={classes.menuButton} href="#contact">Contact</Button>
          </Toolbar>
        </AppBar>
        <div className={classes.heroContent}>
          <HeroTitle className={classes.title} />
          <HeroSubtitle className={classes.subtitle} />
          <Grid container spacing={16} justify="center" className={classes.heroButtons}>
            <Grid item>
              <Button variant="contained" color="primary" size="large" href="#contact">
                Get in touch
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hero);