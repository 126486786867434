import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Section from './Section';
// import lightBlue from '@material-ui/core/colors/lightBlue';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as NodeJSIcon } from '@mdi/svg/svg/nodejs.svg';
import { ReactComponent as ReactIcon } from '@mdi/svg/svg/react.svg';
import { ReactComponent as AngularIcon } from '@mdi/svg/svg/angular.svg';
import { ReactComponent as DatabaseIcon } from '@mdi/svg/svg/database.svg';
import { ReactComponent as EthereumIcon } from '@mdi/svg/svg/ethereum.svg';
import { ReactComponent as DockerIcon } from '@mdi/svg/svg/docker.svg';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';
import brown from '@material-ui/core/colors/brown';

const styles = theme => ({
  container: {
    // backgroundColor: blueGrey[50],
  },
  text: {
    // color: theme.palette.background.default,
  },
});

function Service({ title, children }) {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" color="textPrimary" gutterBottom>
        {title}
      </Typography>
      {children}
    </React.Fragment>
  );
}

function Services(props) {
  const { classes } = props;

  return (
    <Section extraClasses={classes.container} id="services">
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Service title="Planning &amp; analysis">
            <p>We can help you with technical decisions when it comes to feasibility studying, system architecture, migration, or reviewing an existing solution.</p>   
          </Service>
        </Grid>
        <Grid item xs={12}>
          <Service title="Implementation">
            <p>We specialise in delivering state of the art code that will answer all your project requirements.</p>
            <p>With a preference to Javascript-based technologies, our favourite stack is:</p>
            <List>
              <ListItem>
                <NodeJSIcon fill={green[400]} />
                <ListItemText primary="Micro services or functions in NodeJS" secondary="Backend" />
              </ListItem>
              <ListItem>
                <AngularIcon fill={red[400]} />
                <ListItemText primary="Web applications in React or Angular" secondary="Frontend" />
              </ListItem>
              <ListItem>
                <ReactIcon fill={blue[400]} />
                <ListItemText primary="Mobile applications in React Native" secondary="Mobile" />
              </ListItem>
              <ListItem>
                <DatabaseIcon fill={brown[400]} />
                <ListItemText primary="Database(s) chosen depending on the data format and how it will be used" secondary="Database" />
              </ListItem>
              <ListItem>
                <EthereumIcon fill={indigo[400]} />
                <ListItemText primary="Decentralised applications for more transparency in distributed and trustless environments" secondary="Trustless environments" />
              </ListItem>
              <ListItem>
                <DockerIcon fill={lightBlue[400]} />
                <ListItemText primary="Services and webapps distributed as Docker images, while libraries and reusable components distributed as npm modules" secondary="Packaging" />
              </ListItem>
            </List>
            <p>You have different requirements ? We are flexible, so let's <a href="#contact">get in touch</a> and discuss how we can help.</p>   
          </Service>
        </Grid>
        <Grid item xs={12}>
          <Service title="Automation">
            <p>We love automation, and we heavily rely on continuous integration environments to increase productivity.</p>
            <p>By delegating recurrent tasks like linting, testing, building, and deployment, we can spend more time working on what matters most.</p>
          </Service>
        </Grid>
        <Grid item xs={12}>
          <Service title="Delivery">
            <p>Share your idea implementation with customers and start the adventure.</p>
            <p>We can deploy the solution to your preferred hosting provider or distribute it in a standard artifact format.</p>
          </Service>
        </Grid>
      </Grid>
    </Section>
  );
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);